import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Games from './pages/Games';
import NavBar from './pages/NavBar';
import NoPage from './pages/NoPage';
import Photobook from './pages/Photobook';
import KatieButton from './pages/projects/KatieButton';
import Queens from './pages/projects/Queens/Queens';
import TicTacToe from './pages/projects/TicTacToe';
import Wordle from './pages/projects/Wordle/Wordle';

const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route element={<Photobook />} path="/" />
        <Route path="/projects">
          <Route element={<Games />} index />
          <Route element={<KatieButton />} path="katie-button" />
          <Route element={<TicTacToe />} path="tic-tac-toe" />
          <Route element={<Wordle />} path="wordle" />
          <Route element={<Queens />} path="queens" />
        </Route>
        <Route element={<NoPage />} path="*" />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
