import { COLORS } from './constants';

export const createBoardPosition = (
  hasQueen = false,
  colorIndex = null,
  hasFlag = false,
) => ({
  hasQueen,
  colorIndex,
  hasFlag,
});

export const getBackgroundColor = (colorIndex) => {
  if (colorIndex == null) {
    return 'white';
  }
  return COLORS[colorIndex].backgroundColor;
};

export const getBorderColor = (colorIndex) => {
  if (colorIndex == null) {
    return 'white';
  }
  return COLORS[colorIndex].borderColor;
};

const addColorGroups = (board) => {
  const n = board.length;
  const colorMap = {}; // { color: Set of board positions (array for DFS behavior) }

  // Step 1: Assign each queen a unique color and track positions
  let colorIndex = 0;
  board.forEach((row, i) =>
    row.forEach((pos, j) => {
      if (pos.hasQueen) {
        pos.colorIndex = colorIndex;
        colorMap[colorIndex] = [[i, j]]; // Use an array for expansion
        colorIndex += 1;
      }
    }),
  );

  const directions = [
    [1, 0],
    [0, 1],
    [-1, 0],
    [0, -1],
  ];

  const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);
  const isInBounds = (r, c) => r >= 0 && r < n && c >= 0 && c < n;

  let retries = 0;
  const maxRetries = 500;

  while (
    Object.values(colorMap).reduce(
      (acc, positions) => acc + positions.length,
      0,
    ) <
      n * n &&
    retries < maxRetries
  ) {
    retries += 1;

    // Step 2: Pick a random color to expand
    const colorKeys = Object.keys(colorMap);
    const randomColorIndex = Number(
      colorKeys[Math.floor(Math.random() * colorKeys.length)],
    );
    const positions = colorMap[randomColorIndex];

    // Shuffle positions to introduce randomness
    shuffle(positions);

    let expanded = false;
    for (let i = 0; i < positions.length; i += 1) {
      const [r, c] = positions[i];

      // Shuffle directions and try to expand
      const shuffledDirs = shuffle([...directions]);
      for (let k = 0; k < shuffledDirs.length; k += 1) {
        const [dr, dc] = shuffledDirs[k];
        const nr = r + dr;
        const nc = c + dc;

        if (isInBounds(nr, nc) && board[nr][nc].colorIndex === null) {
          board[nr][nc].colorIndex = randomColorIndex;
          if (hasUniqueSolution(board)) {
            positions.push([nr, nc]); // Add to expansion list
            expanded = true;
            break;
          }

          board[nr][nc].colorIndex = null;
        }
      }

      if (expanded) break; // Move to a new color after one expansion
    }
  }

  return board;
};

const addQueens = (board, numRetries = 0) => {
  const n = board.length;
  const maxRetries = 5;

  const cols = Array.from({ length: n }, (_, i) => i).sort(
    () => Math.random() - 0.5,
  );
  const rows = Array.from({ length: n }, (_, i) => i);

  const placedQueens = rows.reduce((acc, row) => {
    const col = cols.find((c) => isSafe(board, row, c, false));
    if (col !== undefined) {
      board[row][col] = createBoardPosition(true, board[row][col].colorIndex);
      return acc + 1;
    }
    return acc;
  }, 0);

  if (placedQueens < n) {
    if (numRetries < maxRetries) {
      removeQueens(board);
      addQueens(board, numRetries + 1);
    } else {
      throw new Error('Failed to generate queens.');
    }
  }
};

export const removeQueens = (board) => {
  board.forEach((row) => {
    row.forEach((pos) => {
      pos.hasQueen = false;
    });
  });
};

export const removeFlags = (board) => {
  board.forEach((row) => {
    row.forEach((pos) => {
      pos.hasFlag = false;
    });
  });
};

const createEmptyBoard = (n) => {
  return Array.from({ length: n }, () =>
    Array.from({ length: n }, () => createBoardPosition()),
  );
};

const removeColorGroups = (board) => {
  board.forEach((row) => {
    row.forEach((pos) => {
      pos.colorIndex = null;
    });
  });
};

const addColorGroupsWithRetries = (board, maxRetries = 5) => {
  let attempts = 0;
  do {
    removeColorGroups(board);
    addColorGroups(board);
    attempts += 1;
  } while (!isStartingBoardValid(board) && attempts < maxRetries);

  //   if (attempts === maxRetries) {
  //     throw new Error('Failed to generate a board with a unique solution.');
  //   }
};

export const createBoard = (n) => {
  const board = createEmptyBoard(n);
  addQueens(board);
  addColorGroupsWithRetries(board, 30);
  removeQueens(board);
  return board;
};

export const isSafe = (board, row, col, checkColor = true) => {
  const n = board.length;

  // check if there are any queens in the same color group
  if (checkColor) {
    for (let i = 0; i < n; i += 1) {
      for (let j = 0; j < n; j += 1) {
        if (
          board[i][j].hasQueen &&
          board[i][j].colorIndex === board[row][col].colorIndex &&
          i !== row &&
          j !== col
        ) {
          return false;
        }
      }
    }
  }

  // check the row and column are in bounds
  if (row < 0 || row >= n || col < 0 || col >= n) {
    return false;
  }

  // check there are no queens in the row
  for (let j = 0; j < n; j += 1) {
    if (board[row][j].hasQueen && j !== col) return false;
  }

  // check there are no queens in the column
  for (let i = 0; i < n; i += 1) {
    if (board[i][col].hasQueen && i !== row) return false;
  }

  // check there are no queens on col+1, row+1 if it is in bounds
  if (col + 1 < n && row + 1 < n) {
    if (board[row + 1][col + 1].hasQueen) return false;
  }

  // check there are no queens on col+1, row-1 if it is in bounds
  if (col + 1 < n && row - 1 >= 0) {
    if (board[row - 1][col + 1].hasQueen) return false;
  }

  // check there are no queens on col-1, row+1 if it is in bounds
  if (col - 1 >= 0 && row + 1 < n) {
    if (board[row + 1][col - 1].hasQueen) return false;
  }

  // check there are no queens on col-1, row-1 if it is in bounds
  if (col - 1 >= 0 && row - 1 >= 0) {
    if (board[row - 1][col - 1].hasQueen) return false;
  }

  return true;
};

export const isValidSolution = (board) => {
  const n = board.length;
  let queenCount = 0;

  for (let row = 0; row < n; row += 1) {
    for (let col = 0; col < n; col += 1) {
      if (board[row][col].hasQueen) {
        queenCount += 1;
        if (!isSafe(board, row, col)) {
          return false; // If any queen is unsafe, return false immediately
        }
      }
    }
  }

  return queenCount === n; // Ensure exactly n queens are placed
};

const isColorGroupValid = (board) => {
  const n = board.length;
  for (let i = 0; i < n; i += 1) {
    for (let j = 0; j < n; j += 1) {
      if (board[i][j].colorIndex === null) {
        return false;
      }
    }
  }
  return true;
};

const isStartingBoardValid = (board) => {
  return hasUniqueSolution(board) && isColorGroupValid(board);
};

export const hasUniqueSolution = (board) => {
  const n = board.length;
  let solutionCount = 0;
  const newBoard = board.map((row) => row.map((pos) => ({ ...pos })));
  removeQueens(newBoard);

  const placeQueens = (col) => {
    if (col === n) {
      // All queens are placed, check if it's a valid solution
      if (isValidSolution(newBoard)) {
        solutionCount += 1;
      }
      return;
    }

    for (let row = 0; row < n; row += 1) {
      if (
        isSafe(newBoard, row, col) &&
        newBoard[row][col].colorIndex !== null
      ) {
        newBoard[row][col].hasQueen = true;
        placeQueens(col + 1);
        newBoard[row][col].hasQueen = false; // Backtrack
      }
    }
  };

  placeQueens(0);
  return solutionCount === 1;
};

export const countQueens = (board) => {
  return board.reduce((acc, row) => {
    return acc + row.filter((pos) => pos.hasQueen).length;
  }, 0);
};
