import React from 'react';

import { togglePositionStateAction } from './queens_reducer';
import { getBackgroundColor } from './queens_utils';

export default function BoardPosition({
  rowIndex,
  colIndex,
  position,
  dispatch,
  board,
  isSolved,
}) {
  if (!board) {
    return (
      <div
        style={{
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxSizing: 'border-box',
        }}>
        <div className="spinner" />
      </div>
    );
  }

  const n = board.length;
  const defaultBorder = '0.1px solid black'; // Light gray border
  const highlightedBorder = '1.5px solid black';
  const thickBorder = '3px solid black'; // Thicker border for outer edges

  let topBorder = defaultBorder;
  let bottomBorder = defaultBorder;
  let leftBorder = defaultBorder;
  let rightBorder = defaultBorder;
  let borderRadius = 0;

  // Apply normal border logic, avoiding optional chaining
  if (
    rowIndex === 0 ||
    (rowIndex > 0 &&
      board[rowIndex - 1] &&
      board[rowIndex - 1][colIndex] &&
      board[rowIndex - 1][colIndex].colorIndex !== position.colorIndex)
  ) {
    topBorder = highlightedBorder;
  }

  if (
    rowIndex === n - 1 ||
    (rowIndex < n - 1 &&
      board[rowIndex + 1] &&
      board[rowIndex + 1][colIndex] &&
      board[rowIndex + 1][colIndex].colorIndex !== position.colorIndex)
  ) {
    bottomBorder = highlightedBorder;
  }

  if (
    colIndex === 0 ||
    (colIndex > 0 &&
      board[rowIndex] &&
      board[rowIndex][colIndex - 1] &&
      board[rowIndex][colIndex - 1].colorIndex !== position.colorIndex)
  ) {
    leftBorder = highlightedBorder;
  }

  if (
    colIndex === n - 1 ||
    (colIndex < n - 1 &&
      board[rowIndex] &&
      board[rowIndex][colIndex + 1] &&
      board[rowIndex][colIndex + 1].colorIndex !== position.colorIndex)
  ) {
    rightBorder = highlightedBorder;
  }

  // Apply thicker borders to the outer edges
  if (rowIndex === 0) topBorder = thickBorder;
  if (rowIndex === n - 1) bottomBorder = thickBorder;
  if (colIndex === 0) leftBorder = thickBorder;
  if (colIndex === n - 1) rightBorder = thickBorder;

  // Round the four corners of the board
  if (rowIndex === 0 && colIndex === 0) borderRadius = '8px 0 0 0'; // Top-left
  if (rowIndex === 0 && colIndex === n - 1) borderRadius = '0 8px 0 0'; // Top-right
  if (rowIndex === n - 1 && colIndex === 0) borderRadius = '0 0 0 8px'; // Bottom-left
  if (rowIndex === n - 1 && colIndex === n - 1) borderRadius = '0 0 8px 0'; // Bottom-right

  return (
    <div
      disabled={isSolved}
      role="button"
      style={{
        width: 40,
        height: 40,
        backgroundColor: getBackgroundColor(position.colorIndex),
        borderTop: topBorder,
        borderBottom: bottomBorder,
        borderLeft: leftBorder,
        borderRight: rightBorder,
        borderRadius,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'relative', // Allows absolute positioning for the X
      }}
      tabIndex="0"
      onClick={() => dispatch(togglePositionStateAction(rowIndex, colIndex))}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          dispatch(togglePositionStateAction(rowIndex, colIndex));
        }
      }}>
      {position.hasQueen ? '👑' : ''}
      {position.hasFlag && (
        <div
          style={{
            position: 'absolute',
            width: '15%',
            height: '0.5px',
            backgroundColor: 'black',
            transform: 'rotate(45deg)',
          }}
        />
      )}
      {position.hasFlag && (
        <div
          style={{
            position: 'absolute',
            width: '15%',
            height: '0.5px',
            backgroundColor: 'black',
            transform: 'rotate(-45deg)',
          }}
        />
      )}
    </div>
  );
}
