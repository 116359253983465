import React from 'react';

import BoardPosition from './BoardPosition';

export default function Board({ board, dispatch, isSolved }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${board.length}, 40px)`,
      }}>
      {board.map((row, rowIndex) =>
        row.map((position, colIndex) => (
          <BoardPosition
            // eslint-disable-next-line react/no-array-index-key
            key={`cell-${position.color}-${rowIndex}-${colIndex}`}
            board={board}
            colIndex={colIndex}
            dispatch={dispatch}
            isSolved={isSolved}
            position={position}
            rowIndex={rowIndex}
          />
        )),
      )}
    </div>
  );
}
