import {
  countQueens,
  createBoard,
  isValidSolution,
  removeFlags,
  removeQueens,
} from './queens_utils';
// Action Types
export const INIT_BOARD = 'INIT_BOARD';
export const RESET_BOARD = 'RESET_BOARD';
export const SET_BOARD_SIZE = 'SET_BOARD_SIZE';
export const TOGGLE_POSITION_STATE = 'TOGGLE_POSITION_STATE';

// Initial State
export const initialState = {
  board: createBoard(8), // Default 8x8 board
  boardSize: 8,
  queenCount: 0,
  isSolved: false,
};

// Action Creators
export const initBoard = (size) => ({
  type: INIT_BOARD,
  payload: size,
});

export const togglePositionStateAction = (row, col) => ({
  type: TOGGLE_POSITION_STATE,
  payload: { row, col },
});

export const resetBoardAction = () => ({
  type: RESET_BOARD,
});

export const setBoardSizeAction = (size) => ({
  type: SET_BOARD_SIZE,
  payload: size,
});

// Reducer
export const queensReducer = (state, action) => {
  if (action.type === INIT_BOARD) {
    const size = action.payload;
    return {
      ...state,
      board: createBoard(size),
      boardSize: size,
      queenCount: 0,
      isSolved: false,
    };
  }
  if (action.type === RESET_BOARD) {
    removeQueens(state.board);
    removeFlags(state.board);
    return {
      ...state,
      board: state.board,
      queenCount: countQueens(state.board),
      isSolved: isValidSolution(state.board),
    };
  }
  if (action.type === SET_BOARD_SIZE) {
    const newSize = action.payload;
    const newBoard = createBoard(newSize);
    return {
      ...state,
      boardSize: newSize,
      board: newBoard,
      queenCount: countQueens(newBoard),
      isSolved: isValidSolution(newBoard),
    };
  }

  if (action.type === TOGGLE_POSITION_STATE) {
    const { row, col } = action.payload;

    if (state.isSolved) {
      return state;
    }

    const newBoard = state.board.map((boardRow, rIdx) =>
      boardRow.map((pos, cIdx) => {
        if (rIdx === row && cIdx === col) {
          if (!pos.hasQueen && !pos.hasFlag) {
            return { ...pos, hasFlag: true };
          }
          if (pos.hasFlag) {
            return { ...pos, hasFlag: false, hasQueen: true };
          }
          if (pos.hasQueen) {
            return { ...pos, hasQueen: false, hasFlag: false };
          }
        }
        return pos;
      }),
    );

    const isSolved = isValidSolution(newBoard);
    if (isSolved) {
      removeFlags(newBoard);
    }

    return {
      ...state,
      board: newBoard,
      queenCount: countQueens(newBoard),
      isSolved,
    };
  }

  return state;
};
