import React, { useEffect, useReducer, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

import Board from './Board';
import {
  initBoard,
  initialState,
  queensReducer,
  resetBoardAction,
} from './queens_reducer';

const Queens = () => {
  const [state, dispatch] = useReducer(queensReducer, initialState);
  const [time, setTime] = useState(0);
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    let timer;
    if (timerActive) {
      timer = setInterval(() => {
        setTime((prev) => prev + 1);
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [timerActive]); // Re-run effect if timerActive changes

  // Stop timer when game is solved
  useEffect(() => {
    if (state.isSolved) {
      setTimerActive(false);
    }
  }, [state.isSolved]);

  // Reset game and restart timer
  const resetGame = () => {
    setTime(0);
    setTimerActive(true);
    dispatch(resetBoardAction());
  };

  const newGame = () => {
    setTime(0);
    setTimerActive(true);
    dispatch(initBoard(state.boardSize));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
      }}>
      {state.isSolved && (
        <ConfettiExplosion duration="3000" particleCount="250" />
      )}
      <h1>Queens Game</h1>
      <Board
        board={state.board}
        dispatch={dispatch}
        isSolved={state.isSolved}
      />

      {/* Timer Display */}
      <div style={{ marginTop: '10px', fontSize: '18px', fontWeight: 'bold' }}>
        Time: {Math.floor(time / 60)}:{(time % 60).toString().padStart(2, '0')}
      </div>

      {/* Buttons */}
      <div style={{ display: 'flex', gap: '12px', marginTop: '20px' }}>
        <button
          style={{
            backgroundColor: 'white',
            border: '2px solid black',
            padding: '12px 24px',
            borderRadius: '12px',
            fontSize: '16px',
            cursor: 'pointer',
          }}
          type="button"
          onClick={resetGame}>
          Clear
        </button>

        <button
          style={{
            backgroundColor: 'white',
            border: '2px solid black',
            color: 'black',
            padding: '12px 24px',
            borderRadius: '12px',
            fontSize: '16px',
            cursor: 'pointer',
          }}
          type="button"
          onClick={newGame}>
          New Game
        </button>
      </div>
    </div>
  );
};

export default Queens;
