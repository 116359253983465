export const createColorGroup = (backgroundColor, borderColor) => ({
  backgroundColor,
  borderColor,
});

export const COLORS = [
  createColorGroup('#B7A4DE', '#8E24AA'), // Soft Purple
  createColorGroup('#E8F395', '#827717'), // Light Yellow-Green
  createColorGroup('#F7CB9A', '#D84315'), // Soft Peach
  createColorGroup('#92B5F6', '#1976D2'), // Sky Blue
  createColorGroup('#E2E2E2', '#424242'), // Light Gray
  createColorGroup('#EC8E7E', '#D32F2F'), // Soft Red
  createColorGroup('#A8D8A4', '#2E7D32'), // Mint Green
  createColorGroup('#B2EBF2', '#00838F'), // Light Cyan
  createColorGroup('#F8BBD0', '#C2185B'), // Soft Pink
  createColorGroup('#EEEEEE', '#424242'), // Light Gray
  createColorGroup('#FFF3E0', '#D84315'), // Beige
  createColorGroup('#D1C4E9', '#5E35B1'), // Pale Lavender
  createColorGroup('#E0F7FA', '#006064'), // Soft Aqua
  createColorGroup('#F0F4C3', '#827717'), // Soft Lime
  createColorGroup('#FFE0E0', '#B71C1C'), // Pastel Peach
  createColorGroup('#D7FFF1', '#004D40'), // Light Teal
];
