import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import KATIE_BUTTON from '../static/images/katie-button.png';
import QUEENS from '../static/images/queens.jpg';
import TIC_TAC_TOE from '../static/images/tictactoe.png';
import WORDLE from '../static/images/wordle.png';
import ContentCard from '../utils/Content/ContentCard';
import ContentContainer from '../utils/Content/ContentContainer';

const Games = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [location]);

  const KATIE_BUTTON_SNIPPET = `What is she thinking? What does she want? 
  The button knows it all. You will soon discover her deepest wishes and desires`;

  const TIC_TAC_TOE_SNIPPET = `Play against a real life version of Katie. Her skill are unmatched`;

  const WORDLE_SNIPPET = `Find the missing word. Small clue, it is KATIE themed`;

  const QUEENS_SNIPPET = `Place 8 queens on the board without them attacking each other`;

  return (
    <ContentContainer>
      <ContentCard
        alt="KatieButton"
        image={KATIE_BUTTON}
        subtitle={KATIE_BUTTON_SNIPPET}
        title="The Katie Button"
        to="/projects/katie-button"
      />
      <ContentCard
        alt="fluxRopeVisualizer"
        image={TIC_TAC_TOE}
        subtitle={TIC_TAC_TOE_SNIPPET}
        title="Tic Tac Toe"
        to="/projects/tic-tac-toe"
      />
      <ContentCard
        alt="You Wordle or What"
        image={WORDLE}
        subtitle={WORDLE_SNIPPET}
        title="You Wordle or What"
        to="/projects/wordle"
      />
      <ContentCard
        alt="Queens"
        image={QUEENS}
        subtitle={QUEENS_SNIPPET}
        title="Queens"
        to="/projects/queens"
      />
    </ContentContainer>
  );
};

export default Games;
